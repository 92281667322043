import React from "react";

const HelpAndAdviceBestDeal = () => {
  return (
    <div className="help-and-advice-best-deals container py-5 m-auto">
      <div className="row align-items-center">
        <div className="col-md-5 col-sm-12 mb-3 text-center">
          <img
            src="https://www.wavvehicles.co.uk/img-src/_themev2-route57-559/theme/large-wav-home.png"
            alt=""
            className="img-fluid"
            style={{ maxWidth: "100%" }}
          />
        </div>
        <div className="col-md-7 col-sm-12 mb-3">
          <div className="text ml-0">
            <h1>
              Help, advice and <span className="text-danger">the best deal</span>
            </h1>
            <div className="subtitle">
              We can help simplify the buying process and provide the key important
              help and advice you need when buying a WAV.
            </div>
            <p>
              Wheelchair accessible vehicles are commonly referred to as ‘WAVs’ –
              which are essentially vehicles that are designed to accommodate the
              travel needs of passengers or drivers using a wheelchair. WAVs make
              lives easier and safer with the main benefit that you can remain
              seated in your own wheelchair without the need of wheelchair to car
              seat transfers.
            </p>
            <div className="footer">— SCOTT SPENCER, Founder of WAV Compare</div>
          </div>
        </div>
      </div>
      {/* <div className="image mt-5">
        <img
          src="https://www.wavvehicles.co.uk/img-src/_themev2-route57-559/theme/large-wav-home.png"
          alt=""
          className="img-fluid"
        />
      </div>
      <div className="text">
        <h1>
          Help, advice and <span className="text-danger">the best deal</span>
        </h1>
        <div className="subtitle">
          We can help simplify the buying process and provide the key important
          help and advice you need when buying a WAV.
        </div>
        <p>
          Wheelchair accessible vehicles are commonly referred to as ‘WAVs’ –
          which are essentially vehicles that are designed to accommodate the
          travel needs of passengers or drivers using a wheelchair. WAVs make
          lives easier and safer with the main benefit that you can remain
          seated in your own wheelchair without the need of wheelchair to car
          seat transfers.
        </p>
        <div className="footer">— SCOTT SPENCER, Founder of WAV Compare</div>
      </div> */}
    </div>
  );
};

export default HelpAndAdviceBestDeal;

import React from "react";
import { BsCheckLg } from "react-icons/bs";
const HireCards = ({ 
  setSize, 
  selectedCar,
  selectedDate,
  setDuration,
  setSelectedDate,
  setSelectedCar 
}) => {


  return (
    <div className="hire-cards">
      <div className="hire-car-cards">
        <h3>What size of WAV do you wish to hire?</h3>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              onClick={() => {
                setSelectedCar("first-hire-car-card");
                setSize("small");
              }}
              className={
                selectedCar === "first-hire-car-card"
                  ? "hire-card hire-card-selected"
                  : "hire-card"
              }
            >
              <div className="hire-card-header">
                {selectedCar === "first-hire-car-card" && (
                  <span>
                    <BsCheckLg />
                  </span>
                )}
                Adapted Vehicle
              </div>
              <div className="hire-card-img">
                <img
                  className="img-fluid"
                  src="https://316xavyzpk7494f8p6dpp5n5-wpengine.netdna-ssl.com/wp-content/uploads/braunability-blue-chrysler-transparent.png"
                  alt="car"
                />
              </div>
              <div className="hire-card-footer">Adapted Vehicle</div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              onClick={() => {
                setSelectedCar("second-hire-car-card");
                setSize("medium");
              }}
              className={
                selectedCar === "second-hire-car-card"
                  ? "hire-card hire-card-selected"
                  : "hire-card"
              }
            >
              <div className="hire-card-header">
                {selectedCar === "second-hire-car-card" && (
                  <span>
                    <BsCheckLg />
                  </span>
                )}
                Small WAV
              </div>
              <div className="hire-card-img">
                <img
                  className="img-fluid"
                  src="https://316xavyzpk7494f8p6dpp5n5-wpengine.netdna-ssl.com/wp-content/uploads/braunability-blue-chrysler-transparent.png"
                  alt="car"
                />
              </div>
              <div className="hire-card-footer">Small WAV</div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              onClick={() => {
                setSelectedCar("third-hire-car-card");
                setSize("large");
              }}
              className={
                selectedCar === "third-hire-car-card"
                  ? "hire-card hire-card-selected"
                  : "hire-card"
              }
            >
              <div className="hire-card-header">
                {selectedCar === "third-hire-car-card" && (
                  <span>
                    <BsCheckLg />
                  </span>
                )}
                Large WAV
              </div>
              <div className="hire-card-img">
                <img
                  className="img-fluid"
                  src="https://316xavyzpk7494f8p6dpp5n5-wpengine.netdna-ssl.com/wp-content/uploads/braunability-blue-chrysler-transparent.png"
                  alt="car"
                />
              </div>
              <div className="hire-card-footer">Large WAV</div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              onClick={() => {
                setSelectedCar("fourth-hire-car-card");
                setSize("extra_large");
              }}
              className={
                selectedCar === "fourth-hire-car-card"
                  ? "hire-card hire-card-selected"
                  : "hire-card"
              }
            >
              <div className="hire-card-header">
                {selectedCar === "fourth-hire-car-card" && (
                  <span>
                    <BsCheckLg />
                  </span>
                )}
                Wheelchair Minibus
              </div>
              <div className="hire-card-img">
                <img
                  className="img-fluid"
                  src="https://316xavyzpk7494f8p6dpp5n5-wpengine.netdna-ssl.com/wp-content/uploads/braunability-blue-chrysler-transparent.png"
                  alt="car"
                />
              </div>
              <div className="hire-card-footer">Wheelchair Minibus</div>
            </div>
          </div>
        </div>
      </div>

      <div className="hire-date-cards">
        <h3>How long do you wish to hire?</h3>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              onClick={() => {
                setSelectedDate("first-hire-date-card");
                setDuration("1 to 3 days");
              }}
              className={
                selectedDate === "first-hire-date-card"
                  ? "hire-card hire-card-selected"
                  : "hire-card"
              }
            >
              <div className="hire-card-content">
                {selectedDate === "first-hire-date-card" && (
                  <span>
                    <BsCheckLg />
                  </span>
                )}
                1 - 3 Days
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              onClick={() => {
                setSelectedDate("second-hire-date-card");
                setDuration("1 to 2 weeks");
              }}
              className={
                selectedDate === "second-hire-date-card"
                  ? "hire-card hire-card-selected"
                  : "hire-card"
              }
            >
              <div className="hire-card-content">
                {selectedDate === "second-hire-date-card" && (
                  <span>
                    <BsCheckLg />
                  </span>
                )}
                1 - 2 Weeks
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              onClick={() => {
                setSelectedDate("third-hire-date-card");
                setDuration("1-3 months");
              }}
              className={
                selectedDate === "third-hire-date-card"
                  ? "hire-card hire-card-selected"
                  : "hire-card"
              }
            >
              <div className="hire-card-content">
                {selectedDate === "third-hire-date-card" && (
                  <span>
                    <BsCheckLg />
                  </span>
                )}
                1 - 3 Months
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-md-6 col-lg-3">
            <div
              onClick={() => {
                setSelectedDate("fourth-hire-date-card");
                setDuration("3+ months");
              }}
              className={
                selectedDate === "fourth-hire-date-card"
                  ? "hire-card hire-card-selected"
                  : "hire-card"
              }
            >
              <div className="hire-card-content">
                {selectedDate === "fourth-hire-date-card" && (
                  <span>
                    <BsCheckLg />
                  </span>
                )}
                3+ Months
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HireCards;

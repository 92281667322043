import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

export default function PriceSlider({ value, setValue, setMinPrice, setMaxPrice }) {
  const minPriceValue = 0;

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minPriceValue), value[1]]);
      setMinPrice(value[0]);
      setMaxPrice(value[1]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minPriceValue)]);
      setMinPrice(value[0]);
      setMaxPrice(value[1]);
    }
  };

  return (
    <div className="price-slider" style={{ width: "80%", margin: "0 auto" }}>
      <h5
        style={{
          color: "#002e5b",
          textAlign: "left",
          margin: "20px 0px",
          fontSize: "16px",
        }}
      >
        Select Price
      </h5>
      <Box sx={{ width: "95%", marginX: "auto" }}>
        <Slider
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          disableSwap
          max={100000}
          min={0}
        />
      </Box>
      {
        (value && value.length) ?
        <div className="price-slider-values">
          <div className="price-slider-value">{value[0]}</div>
          <div className="price-slider-value">{value[1]}</div>
        </div>
        : ''
      }
    </div>
  );
}

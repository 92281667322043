import React, { useEffect, useState } from "react";
import ProductCard from "./ProductCard";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getWav } from "../../redux/actions/wavActions";
import { FaTelegramPlane } from "react-icons/fa";
import "./style.scss";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;
const ProductPage = () => {
  const [wav, setWav] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  
  const params = useParams();
  const dispatch = useDispatch();
  const selectedWavFromState = useSelector((state) => state.wavs.selected);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getWav(params.id));
  }, [params.id]);
  useEffect(() => {
    setWav(selectedWavFromState);
  }, [selectedWavFromState]);

  const sendEmail = async () => {
    const emailObj = {
      name,
      email,
      phone,
      message,
      subject,
      toEmail: wav && wav.owner && wav.owner.email,
      pageUrl: window.location.href
    };
    try {
      const response = await fetch(`${url}/dealer/contact/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailObj),
      });
      if (response.ok) {
        const data = await response.json();
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");

        setSubject("");
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        const data = await response.json();
        console.log("error: ", data);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    wav && wav.owner && wav.owner.email && sendEmail();
  };
  return (
    <div className="product-page">
      <div className="product-nav">
        {/* <h1>FIND & COMPARE OVER 500 WAVS FOR SALE</h1> */}
      </div>
      <div className="product-wrapper">
        <div className="product-main-content">
          {wav && <ProductCard wav={wav} />}
        </div>
        <div className="product-sidebar">
          <div className="product-sidebar-header">
            <h1>
              <span>
                <FaTelegramPlane />
              </span>
              CONTACT DEALER
            </h1>
          </div>
          <div className="product-sidebar-main">
            <div className="product-sidebar-main-company">
              <img
                src={
                  wav && wav.owner
                    ? `https://dev.wavcompare.com/uploads/${wav.owner.logo}`
                    : "/assets/user.jpg"
                }
                alt="logo"
              />
              <h5>{wav && wav.owner && wav.owner.name}</h5>
            </div>
            <div className="product-sidebar-main-contact-details">
              <div className="product-sidebar-main-contact-detail">
                <div className="icon">
                  <BsFillTelephoneFill />
                </div>
                <div className="normal-text">
                  {wav && wav.owner && wav.owner.phone_number}
                </div>
              </div>
              <div className="product-sidebar-main-contact-detail">
                <div className="icon">
                  <HiLocationMarker />
                </div>
                <div className="normal-text">
                  {wav && wav.owner && wav.owner.address}
                </div>
              </div>
              <div className="product-sidebar-main-contact-details-inputs">
                <h3>Drop us a line</h3>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      className="form-control"
                      id="name"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="form-control"
                      id="email"
                      placeholder="Email Address"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      className="form-control"
                      id="phone"
                      placeholder="Phone Number"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                      className="form-control"
                      id="subject"
                      placeholder="Subject of your message"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      className="form-control"
                      id="message"
                      placeholder="Message"
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="form-check d-flex align-items-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="defaultCheck1"
                    />
                    <label className="form-check-label" htmlFor="defaultCheck1">
                      <small style={{ fontSize: "10px", marginLeft: "10px" }}>
                        I accept the terms of Privacy Policy. <br /> Privacy
                        Policy can be found here.
                      </small>
                    </label>
                  </div>
                  <div className="form-button mt-4">
                    <button type="submit" className="btn ">
                      Send
                    </button>
                  </div>
                  {success && (
                    <div className="alert alert-success mt-3" role="alert">
                      Your message has been sent successfully
                    </div>
                  )}
                  {error && (
                    <div className="alert alert-danger mt-3" role="alert">
                      Your message has not been sent. Please fill all the
                      details.
                    </div>
                  )}
                </form>
              </div>
            </div>
            <ul></ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPage;

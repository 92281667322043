import { Link } from 'react-router-dom';
import './style.css'
const Checkmail = () => {
    return (
        <>

            <h1>Check your email</h1>
            <div className='mt-4 mb-3 black_text px-3'>
                <p className='mb-2'>A link to reset your password has been sent to your email.</p>
                {/* <p className='mb-2'>The link will expire in 24 Hours.</p> */}
                <p className='mb-2'>Please check your spam/junk folder if you haven't received anything through!</p>
            </div>
            <p className='pl-3'><Link to="/">Resend password reset email</Link></p>
            <p className='pl-3 mt-2'><Link to="/">Use a different email address</Link></p>
            {/* EMAIL RESENT! */}
            <h6 className='mail_sent_text pl-3 mt-4'>EMAIL RESENT!</h6>
            {/* EMAIL RESENT! */}

        </>
    )
}
export default Checkmail;
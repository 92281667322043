import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import MainNavbar from "./Components/Navbar/MainNavbar";
import HomePage from "./Components/HomePage/HomePage";
import Footer from "./Components/Footer/Footer";
import SearchResults from "./Components/Search/SearchResults";
import ProductPage from "./Components/Product/ProductPage";
import Dealers from "./Components/Dealers/Dealers";
import DealerPage from "./Components/Dealers/DealerPage";
import HelpAndAdvice from "./Components/HelpAndAdvice/HelpAndAdvice";
import SellYourWav from "./Components/Sell/SellYourWav";
import Hire from "./Components/Hire/Hire";
import ContactUs from "./Components/ContactUs/ContactUs";
import SignUpSignIn from "./Components/SignUpSignIn/SignUpSignIn";
import Dmuk from "./Components/DMUK/Dmuk";
import CancelAds from "./Components/CancelAds/CancelAds";
import MiniBus from "./Components/MiniBus/MiniBus";
import Profile from "./Components/Profile/Profile";
import SearchAndBuy from "./Components/Search/SearchAndBuy";
import Admin from "./Components/Admin/Admin";
import { useDispatch, useSelector } from "react-redux";
import ProtectedProfile from "./Components/Protected/ProtectedProfile";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { CHECK_LOGGED_IN_USER } from "./redux/constants/constants";
import ProtectedSigninSignup from "./Components/Protected/ProtectedSigninSignup";

import { selectComponent, toggleModal } from "./redux/actions/dealerActions";

function App() {
  const auth = useSelector((state) => state.dealers.authenticated);
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const jwt = cookies.get("jwt");
  const user = cookies.get("user");

  useEffect(() => {
    if (jwt && user) {
      dispatch({ type: CHECK_LOGGED_IN_USER, payload: user });
    }
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const state = query.get('state')
    const token = query.get('token')
    if (state) {
      if (state == 'valid') {
        dispatch(toggleModal(true))
        dispatch(selectComponent('change_password'))
      }
      else {
        dispatch(toggleModal(true))
        dispatch(selectComponent('reset_password'))
      }
    }

  }, [])

  // useEffect(() => {
  //   let urlArr = window.location.pathname.split("/")
  //   console.log("urlArr", urlArr)

  //   if (urlArr[1] == 'reset-password') {
  //     dispatch(selectComponent("change_password"))
  //     console.log("RESET PASSWORD")
  //     dispatch(toggleModal(true))
  //   }
  // }, [])
  return (
    <div className="App">
      <div className="main">
        {/* <MainNavbar />
        <Navbar1 /> */}
        <Router>
          <Route path="/" component={MainNavbar} />
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/buy">
            <SearchAndBuy name="buy" />
          </Route>
          <Route exact path="/search">
            <SearchResults name="search" />
          </Route>
          {/* <Route exact path="/admin/:id" component={Admin} /> */}
          <Route exact path="/wavs/:id" component={ProductPage} />
          <Route exact path="/dealers" component={Dealers} />
          <Route exact path="/help" component={HelpAndAdvice} />
          <Route exact path="/dealers/:id" component={DealerPage} />
          <Route
            exact
            path="/sell-your-wav-private-seller"
            component={SellYourWav}
          />
          <Route exact path="/hire" component={Hire} />
          <Route exact path="/contact-us" component={ContactUs} />
          <ProtectedSigninSignup exact path="/signup">
            <SignUpSignIn />
          </ProtectedSigninSignup>
          <Route exact path="/dmuk" component={Dmuk} />
          <Route exact path="/cancel-ads" component={CancelAds} />
          <Route
            exact
            path="/wheelchair-accessible-minibus"
            component={MiniBus}
          />
          <ProtectedProfile exact path="/profile/:id">
            <Profile />
          </ProtectedProfile>
        </Router>
      </div>
    </div>
  );
}

export default App;

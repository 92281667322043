import React from "react";

const HelpAndAdviceSize = () => {
  return (
    <div className="help-and-advice-size">
      <div className="content">
        <h1>What size of WAV do I need?</h1>
        <p>
          This will depend on what you want to do with your wheelchair
          accessible vehicle. A small WAV has room for the driver, one
          wheelchair user, and sometimes one other passenger. These are ideal
          for running around town as they are economical and easy to park. If,
          however you want to carry more passengers or lots of luggage, you will
          need to look for a larger vehicle. Remember to take into account where
          you will be parking the vehicle and if its suitable to fit in your
          garage, on your drive or on the road.
        </p>
      </div>
    </div>
  );
};

export default HelpAndAdviceSize;

import {
  CLEAR_FILTERS,
  GET_WAV,
  GET_WAVS,
  GET_WAV_DETAILS,
  SEARCH_WAVS,
} from "../constants/constants";
import { initialStore } from "../store";

export const wavReducer = (state = initialStore.wavs, action) => {
  switch (action.type) {
    case GET_WAVS: {
      return {
        ...state,
        list: action.payload,
      };
    }
    case GET_WAV: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case SEARCH_WAVS: {
      return {
        ...state,
        searched: action.payload.wavs,
        searchedWords: action.payload.searchArray,
      };
    }
    case CLEAR_FILTERS: {
      return {
        ...state,
        searchedWords: [],
      };
    }
    case GET_WAV_DETAILS: {
      return { ...state, details: action.payload };
    }
    default:
      return state;
  }
};

import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export default function HireDatePicker({ setStartDate, startDate }) {
  const handleChange = (newValue) => {
    setStartDate(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <div className="desktop">
          <DesktopDatePicker
            label="Start date"
            inputFormat="MM/dd/yyyy"
            value={startDate}
            onChange={handleChange}
            required
            renderInput={(params) => (
              <TextField style={{ width: "100%" }} {...params} />
            )}
          />
        </div>
        <div className="mobile">
          <MobileDatePicker
            label="Start date"
            inputFormat="MM/dd/yyyy"
            required
            value={startDate}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField style={{ width: "100%" }} {...params} />
            )}
          />
        </div>
      </Stack>
    </LocalizationProvider>
  );
}

import {
  GET_WAVS,
  GET_WAV,
  SEARCH_WAVS,
  GET_WAV_DETAILS,
} from "../constants/constants";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

export const getWavs = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/wav/all`, {
        method: "POST",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_WAVS, payload: data.data.wavs });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const getWav = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/wav/${id}`, {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_WAV, payload: data.data.wav });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const searchWavs = (
  body = "",
  make = "",
  model = "",
  mileage = "",
  size = "",
  transmission = "",
  seating = "",
  entrance = "",
  doors = "",
  year = "",
  price = "",
  keywords = [],
  type
) => {
  return async (dispatch) => {
    try {
      const mileageStartIndex = mileage.indexOf("-");
      const mileageStart = parseInt(mileage.substring(0, mileageStartIndex));
      const mileageEnd = parseInt(mileage.substring(mileageStartIndex + 1));

      const bodyStr = body === "" ? "" : `body=${body}`;
      const makeStr = make === "" ? "" : `&make=${make}`;
      const modelStr = model === "" ? "" : `&models=${model}`;
      // const mileageStr = mileage === "" ? "" : `&mileage=${mileage}`;
      const sizeStr = size === "" ? "" : `&size=${size}`;
      const transmissionStr =
        transmission === "" ? "" : `&transmission=${transmission}`;
      const seatingStr = seating === "" ? "" : `&seating=${seating}`;
      const entranceStr = entrance === "" ? "" : `&entrance=${entrance}`;
      const doorsStr = doors === "" ? "" : `&doors=${doors}`;
      const yearStr = year === "" ? "" : `&year=${year}`;
      const priceStr = price === "" ? "" : `&price=${price}`;

      const response = await fetch(
        `${url}/wav/all?${bodyStr}${makeStr}${modelStr}${sizeStr}${transmissionStr}${seatingStr}${entranceStr}${doorsStr}${yearStr}${priceStr}`,
        {
          method: "POST",
          body: JSON.stringify({ features: keywords }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        const searchArray = [
          body !== "" ? { name: "body", value: body } : null,
          make !== "" ? { name: "make", value: make } : null,
          model !== "" ? { name: "model", value: model } : null,
          mileage !== "" ? { name: "mileage", value: mileage } : null,
          transmission !== ""
            ? { name: "transmission", value: transmission }
            : null,
          seating !== "" ? { name: "seating", value: seating } : null,
          entrance !== "" ? { name: "entrance", value: entrance } : null,
          doors !== "" ? { name: "doors", value: doors } : null,
          year !== "" ? { name: "year", value: year } : null,
          price !== "" ? { name: "price", value: price } : null,
        ];
        const adaptationFilter =
          type === "passengers"
            ? data.data.wavs &&
              data.data.wavs.length > 0 &&
              data.data.wavs.filter(
                (wav) => wav.wav_type !== "Drive From Wheelchair"
              )
            : type === "drivers"
            ? data.data.wavs &&
              data.data.wavs.length > 0 &&
              data.data.wavs.filter(
                (wav) => wav.wav_type === "Drive From Wheelchair"
              )
            : data.data.wavs;
        console.log("data:", adaptationFilter);
        let filtered = [];
        if (mileage !== "") {
          filtered =
            adaptationFilter &&
            adaptationFilter.length > 0 &&
            adaptationFilter.filter(
              (wav) =>
                parseInt(wav.mileage) >= mileageStart &&
                parseInt(wav.mileage) <= mileageEnd
            );
          console.log("filtered aa:", filtered);
        }
        dispatch({
          type: SEARCH_WAVS,
          payload: {
            wavs: filtered.length > 0 ? filtered : adaptationFilter,
            searchArray,
          },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};
export const getWavDetails = () => {
  return async (dispatch) => {
    try {
      const response = await fetch(`${url}/wav/details`);
      if (response.ok) {
        const data = await response.json();

        dispatch({ type: GET_WAV_DETAILS, payload: data.data });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const GET_WAVS = "GET_WAVS";
export const GET_WAV = "GET_WAV";
export const SEARCH_WAVS = "SEARCH_WAVS";
export const GET_DEALERS = "GET_DEALERS";
export const GET_DEALER = "GET_DEALER";
export const CREATE_DEALER = "CREATE_DEALER";
export const LOGIN_DEALER = "LOGIN_DEALER";
export const LOGOUT_DEALER = "LOGOUT_DEALER";
export const GET_BUYERS = "GET_BUYERS";
export const CHECK_LOGGED_IN_USER = "CHECK_LOGGED_IN_USER";
export const GET_WAV_DETAILS = "GET_WAV_DETAILS";
export const CLEAR_FILTERS = "CLEAR_FILTERS";

export const MODAL_STATE = 'MODAL_STATE'
export const SELECT_COMPONENT = "SELECT_COMPONENT"
export const BACK_COMPONENT = "BACK_COMPONENT"

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { searchWavs } from "../../redux/actions/wavActions";
const imgArray = [
  // { name: "Kia", link: "/assets/KIA.png" },
  { name: "Kia", link: "/assets/kia.png" },
  // { name: "Volkswagen", link: "/assets/VOLKSWAGEN.png" },
  { name: "Volkswagen", link: "/assets/volkswagen.png" },
  { name: "Peugeut", link: "/assets/PEUGEUT.png" },
  // { name: "Mercedes Benz", link: "/assets/MERCEDES.png" },
  { name: "Mercedes Benz", link: "/assets/mercedes.png" },
  { name: "Vauxhall", link: "/assets/VAUXHALL.png" },
  { name: "Nissan", link: "/assets/NISSAN.png" },
  { name: "Skoda", link: "/assets/SKODA.png" },
  // { name: "Toyota", link: "/assets/TOYOTA.png" },
  { name: "Toyota", link: "/assets/toyota.png" },
  { name: "Citroen", link: "/assets/CITROEN.png" },
  // { name: "Fiat", link: "/assets/FIAT.png" },
  { name: "Fiat", link: "/assets/fiat.png" },
  { name: "Ford", link: "/assets/FORD.svg" },
];
const AllMakes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSearch = (make, model) => {
    dispatch(searchWavs("", make, model));
    history.push("/search");
  };

  return (
    <div className="all-makes">
      <h6>All Makes</h6>
      <div className="cards " style={{ height: "100%" }}>
        {imgArray.map((img) => (
          <div
            key={img.name}
            onClick={() => handleSearch(img.name, "")}
            style={{ cursor: "pointer" }}
            className="make"
          >
            <div className="image-wrapper">
              <img
                src={img.link}
                style={
                  img.name === "Kia"
                    ? { height: "65px", width: "100px", marginTop: "5px" }
                    : img.name === "Toyota"
                    ? { width: "100%", height: "65px" }
                    : img.name === "Citroen"
                    ? { width: "100%", height: "65px" }
                    : {}
                }
                className="card-img-top"
                alt="..."
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AllMakes;

import React from "react";

const PrivateSeller = ({ history, setMenuOpen }) => {
  return (
    <div className="seller">
      <div className="text">
        <h1>Private Seller</h1>
        <h4>Sell your WAV quickly as a private seller</h4>
        <button
          onClick={() => {
            history.push("/sell-your-wav-private-seller");
            setMenuOpen(false);
          }}
        >
          Click Here
        </button>
      </div>
      <div className="img">
        <img src="/assets/private.jpg" alt="seller" className="img-fluid" />
      </div>
    </div>
  );
};

export default PrivateSeller;

import React, { useEffect, useState } from "react";
import { FaCar } from "react-icons/fa";
import Cookies from "universal-cookie";
import { getWavs } from "../../redux/actions/wavActions";
import { useDispatch, useSelector } from "react-redux";
import WavListing from "./WavListing";
import { add } from "date-fns";
import AddOrEdit from "./AddOrEdit";
import { FaSearch } from "react-icons/fa";
import { getDealer } from "../../redux/actions/dealerActions";
import moment from "moment";
import { useParams } from "react-router-dom";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;
const YourListing = ({
  editing,
  adding,
  setEditing,
  setAdding,
  getUser,
  dealer,
}) => {
  const [dealerWavs, setDealerWavs] = useState([]);
  const [wav, setWav] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState("active");
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const user = cookies.get("user");
  const dispatch = useDispatch();
  const wavs = useSelector((state) => state.wavs.list);

  const dealerFromState = useSelector((state) => state.dealers.selected);

  const deleteWav = async (id) => {
    try {
      const response = await fetch(`${url}/wav/${id}`, {
        method: "DELETE",
        headers: {
          authorization: `${token}`,
        },
      });
      if (response.ok) {
        dispatch(getWavs());
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getUser();
    dispatch(getWavs());
    dispatch(getDealer(user._id));
  }, []);
  useEffect(() => {
    const sortedWavs = wavs.filter((wav) => {
      if (wav.owner) {
        if (wav.owner._id === user._id) {
          return wav;
        }
      }
    });
    setDealerWavs(sortedWavs);
  }, [wavs]);
  const activeWavs = dealerWavs.filter((wav) => wav.status === "active");
  const filtered =
    dealerWavs &&
    dealerWavs.filter((wav) => wav.models.toLowerCase().includes(searchValue));
  const sortedByStatus =
    status === "" ? filtered : filtered.filter((wav) => wav.status === status);

  const heading = editing
    ? "EDIT YOUR LISTING"
    : adding
    ? "ADD A NEW LISTING"
    : "CONFIGURE YOUR LISTINGS";
  return (
    <div>
      <div className="header">
        <FaCar /> <h3>{heading}</h3>
      </div>
      {adding && (
        <AddOrEdit
          setAdding={setAdding}
          setEditing={setEditing}
          name="adding"
          getUser={getUser}
        />
      )}
      {editing && wav && (
        <AddOrEdit
          setAdding={setAdding}
          setEditing={setEditing}
          wav={wav}
          name="editing"
          getUser={getUser}
        />
      )}

      {!adding && !editing && (
        <div className="wav-listing">
          <div className="wav-actions">
            <div className="credits">
              <div className="action-header">Your Credits</div>
              <div className="action-detail">
                {dealer && dealer.coins && dealer.coins}
              </div>
            </div>
            <div className="wav-length">
              <div className="action-header">Active Listings</div>
              <div className="action-detail">
                {activeWavs && activeWavs.length && activeWavs.length}
              </div>
            </div>
            <div onClick={() => setAdding(true)} className="wav-create">
              <div>
                <img src="/assets/car-plus.svg" alt="" />
              </div>
              <div className="">CREATE LISTING</div>
            </div>
          </div>
          <div className="search-and-next">
            <div className="search d-flex align-items-center">
              <input
                className="form-control mr-sm-2"
                type="search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder="Search"
                aria-label="Search"
              />
            </div>
          </div>
          <div className="lists my-4">
            <div onClick={() => setStatus("")} className="list-item">
              All Listings
            </div>
            <div onClick={() => setStatus("active")} className="list-item">
              Published
            </div>
            <div onClick={() => setStatus("ended")} className="list-item">
              Closed Listings
            </div>
          </div>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Image</th>
                <th scope="col">Model</th>
                <th scope="col">Condtion</th>
                <th scope="col">Price</th>
                <th scope="col">Status</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {!adding &&
                !editing &&
                sortedByStatus &&
                sortedByStatus.length > 0 &&
                sortedByStatus.map((item, i) => (
                  <tr key={item._id}>
                    <th scope="row">{i + 1}</th>
                    <td>
                      <img
                        src={`https://dev.wavcompare.com/uploads/${item.images[0]}`}
                        alt="car logo"
                      />
                    </td>
                    <td>{item.models}</td>
                    <td>{item.condition}</td>
                    <td>{item.price}$</td>
                    <td>{item.status}</td>
                    <td>
                      {moment(new Date(item.created_at)).format(
                        "MMMM Do YYYY, h:mm:ss a"
                      )}
                    </td>
                    <td style={{ display: "flex", flexDirection: "column" }}>
                      <button
                        onClick={() => {
                          setEditing(true);
                          setWav(item);
                        }}
                        style={{ fontSize: "12px" }}
                        className="btn btn-primary btn-sm mb-2"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => deleteWav(item._id)}
                        style={{ fontSize: "12px" }}
                        className="btn btn-danger btn-sm"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default YourListing;

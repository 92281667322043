import React from "react";
import { AiOutlineClear } from "react-icons/ai";
import ArchiveSelect from "./ArchiveSelect";
import PriceSlider from "./PriceSlider";
import SearchResultsFilter from "./SearchResultsFilter";

const SearchSideBar = ({
  detailsFromState,
  setBody,
  setMake,
  setModel,
  setMileage,
  setSize,
  setTransmission,
  setSeating,
  setEntrance,
  setDoors,
  setYear,
  setPrice,
  setArchive,
  search,
  searchValue,
  setSearchValue,
  setMinPrice,
  setMaxPrice,
  setPriceRange,
  minPrice,
  wavs,
  maxPrice,
  make,
  model,
  body,
  year,
  doors,
  entrance,
  seating,
  size,
  mileage,
  archive,
  priceRange,
  transmission,
  resetFilters
}) => {

  const filteredByBody =
    wavs.length > 0 && wavs.filter((wav) => wav.body === body);
  const filteredByMake =
    body !== ""
      ? filteredByBody.filter((wav) => wav.make === make)
      : wavs.length > 0 && wavs.filter((wav) => wav.make === make);
  const filteredByModel =
    make !== ""
      ? filteredByMake.filter((wav) => wav.models === model)
      : wavs.length > 0 && wavs.filter((wav) => wav.models === model);

  const makes =
    body === ""
      ? wavs.length > 0 && wavs.map((wav) => wav.make)
      : filteredByBody.length > 0 && filteredByBody.map((wav) => wav.make);

  const filteredMakes = [];
  const uniqueMakes =
    makes &&
    makes.length > 0 &&
    makes.filter((make) => {
      const isUnique = filteredMakes.includes(make);
      if (!isUnique) {
        filteredMakes.push(make);
      }
    });

  const models =
    make === ""
      ? wavs.length > 0 && wavs.map((wav) => wav.models)
      : filteredByMake.length > 0 && filteredByMake.map((wav) => wav.models);

  const filteredModels = [];
  const uniqueModels =
    models &&
    models.length > 0 &&
    models.filter((model) => {
      const isUnique = filteredModels.includes(model.trim());
      if (!isUnique) {
        filteredModels.push(model);
      }
    });

  return (
    <div>
      <div className="sidebar-header">
        <h1>FILTER</h1>
        <p>Find exactly what you are looking for</p>
      </div>
      <div className="sidebar-main">
        <ul>
          <li>
            <SearchResultsFilter
              name="BODY TYPE"
              list={detailsFromState.body}
              value={body}
              setValue={setBody}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="MAKE"
              list={filteredMakes}
              value={make}
              setValue={setMake}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="MODEL"
              list={filteredModels}
              value={model}
              setValue={setModel}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="MILEAGE"
              list={detailsFromState.mileage}
              value={mileage}
              setValue={setMileage}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="SIZE"
              list={detailsFromState.size}
              value={size}
              setValue={setSize}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="TRANSMISSION"
              list={detailsFromState.transmission}
              value={transmission}
              setValue={setTransmission}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="SEATING"
              list={detailsFromState.seating}
              value={seating}
              setValue={setSeating}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="ENTRANCE"
              list={detailsFromState.entrance}
              value={entrance}
              setValue={setEntrance}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="DOORS"
              list={detailsFromState.doors}
              value={doors}
              setValue={setDoors}
            />
          </li>
          <li>
            <SearchResultsFilter
              name="YEAR"
              list={detailsFromState.year}
              value={year}
              setValue={setYear}
            />
          </li>
          <li>
            <PriceSlider
              value={priceRange}
              setValue={setPriceRange}
              minPrice={minPrice}
              maxPrice={maxPrice}
              setMinPrice={setMinPrice}
              setMaxPrice={setMaxPrice}
            />
          </li>
          {/* <li>
            <SearchResultsFilter
              name="PRICE"
              list={detailsFromState.price}
              setBody={null}
              setMake={null}
              setModel={null}
              setMileage={null}
              setSize={null}
              setTransmission={null}
              setSeating={null}
              setEntrance={null}
              setDoors={null}
              setYear={null}
              setPrice={setPrice}
            />
          </li> */}
          {/* {filterOptions.map((filterOption) => (
            <li key={shortid.generate()}>
              <SearchResultFilter
                name={filterOption.name}
                list={filterOption.list}
              />
            </li>
          ))} */}
        </ul>
        <ul id="filter-search">
          <li>
            <div className="search__container">
              <input
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className="search__input"
                type="text"
                placeholder="Search"
              />
            </div>
          </li>
          <li>
            <div className="archive">
              <h3>ARCHIVE</h3>
              <ArchiveSelect  
                value={archive}
                setValue={setArchive}
              />
            </div>
          </li>
          <li>
            <button className="filter-search-btn" onClick={() => search()}>
              Search
            </button>
            <button onClick={resetFilters} className="filter-search-btn ml-3">
              <AiOutlineClear /> Clear
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SearchSideBar;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
const Trending = ({ details, wavs }) => {
  const [selectedMake, setSelectedMake] = useState("");
  const filteredByStatus = wavs.filter((wav) => wav.status === "active");
  const filtered = filteredByStatus.filter((wav) =>
    wav.make.includes(selectedMake)
  );
  const history = useHistory();


  let price = 0


  return (
    <div className="trending">
      <ul id="trending-nav">
        <li onClick={() => setSelectedMake("")}>
          <a href="#">All Makes</a>
        </li>
        {details &&
          details.make &&
          details.make.length > 0 &&
          details.make.slice(0, 5).map((make) => (
            <li key={make} onClick={() => setSelectedMake(make)}>
              <a href="#">{make}</a>
            </li>
          ))}
      </ul>

      <div className="offers">
        {filtered.slice(0, 4).map((wav) => {
          {
            if (Object.entries(wav).length > 0) {
              wav.price_2 = wav.price.replace(/[^0-9]/g, "")
              price = parseFloat(wav.price_2);
            }
          }
          return (
            <div
              key={wav._id}
              onClick={() => history.push(`/wavs/${wav._id}`)}
              className="card"
              style={{ width: "24%", marginRight: "5px", cursor: "pointer" }}
            >
              <div className="image-wrapper">
                <img
                  src={
                    wav && wav.images && wav.images.length < 1
                      ? "/assets/user.jpg"
                      : `https://dev.wavcompare.com/uploads/${wav.images[0]}`
                  }
                  className="card-img-top fluid"
                  alt="..."
                />
                {/* <span className="price">{wav.price}£</span> */}
                <span className="price">{price}£</span>
              </div>
              <div>{wav.title}</div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default Trending;

import React from "react";

const HireHeader = () => {
  return (
    <div className="hire-header">
      <div className="wrapper">
        <div className="text-wrapper">
          <h1>HIRE</h1>
          <div className="subtitle">
            Hire or lease a vehicle with wheelchair access - available anywhere
            across the UK and delivered to your door!
          </div>
        </div>
      </div>
    </div>
  );
};

export default HireHeader;

import React, { useState, useEffect } from "react";
import "./style.css";
import SearchResultFilter from "./SearchResultsFilter.jsx";
import SearchMainContent from "./SearchMainContent";
import { useSelector, useDispatch } from "react-redux";
import {
  getWavDetails,
  getWavs,
  searchWavs,
} from "../../redux/actions/wavActions";

import SearchSideBar from "./SearchSideBar";

const SearchResults = () => {
  const [wavs, setWavs] = useState([]);
  const [searched, setSearched] = useState([]);
  const detailsFromState = useSelector((state) => state.wavs.details);
  const wavsFromState = useSelector((state) => state.wavs.list);
  const searchedState = useSelector((state) => state.wavs.searched);
  const [body, setBody] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [mileage, setMileage] = useState("");
  const [size, setSize] = useState("");
  const [transmission, setTransmission] = useState("");
  const [seating, setSeating] = useState("");
  const [entrance, setEntrance] = useState("");
  const [doors, setDoors] = useState("");
  const [year, setYear] = useState("");
  const [price, setPrice] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(100000);
  const dispatch = useDispatch();

  const search = () => {
    dispatch(
      searchWavs(
        body,
        make,
        model,
        mileage,
        size,
        transmission,
        seating,
        entrance,
        doors,
        year,
        price
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getWavs());
    dispatch(getWavDetails());
  }, []);
  
  useEffect(() => {
    setWavs(wavsFromState);
  }, [wavsFromState]);

  useEffect(() => {
    setSearched(searchedState);
  }, [searchedState]);

  const filteredWavs =
    searchedState &&
    wavs.filter((wav) => wav.models.toLowerCase().includes(searchValue));
  const filtered =
    searchedState &&
    searchedState.length > 0 &&
    searchedState.filter((wav) => wav.status === "active");

  const filteredByPriceSlider =
    filtered &&
    filtered.length > 0 &&
    filtered.filter((wav) => {
      wav.price = wav.price.replace(/[^0-9]/g, "")
      if (wav.price >= minPrice && wav.price <= maxPrice) {
        return wav;
      }
    });

  return (
    <div className="search-result">
      <div className="search-nav">
        {/* <div className="logo">WAVCompare</div> */}

        <div className="wrapper">
          <div className="text-wrapper">
            <h1>FIND & COMPARE OVER 500 WAVS</h1>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="sidebar">
          <SearchSideBar
            setBody={setBody}
            setMake={setMake}
            setModel={setModel}
            setMileage={setMileage}
            setSize={setSize}
            setTransmission={setTransmission}
            setSeating={setSeating}
            setEntrance={setEntrance}
            setDoors={setDoors}
            setYear={setYear}
            setPrice={setPrice}
            detailsFromState={detailsFromState}
            search={search}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            minPrice={minPrice}
            maxPrice={maxPrice}
            setMinPrice={setMinPrice}
            setMaxPrice={setMaxPrice}
            wavs={filtered}
            make={make}
            model={model}
            body={body}
          />
        </div>
        <div className="main-content">
          {filteredByPriceSlider.length > 0 ? (
            <SearchMainContent
              wavs={filteredByPriceSlider}
              searchValue={searchValue}
              length={filteredByPriceSlider.length}
            />
          ) : (
            <SearchMainContent
              wavs={filtered}
              searchValue={searchValue}
              length={filtered.length}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResults;

import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { AiFillStar } from "react-icons/ai";
import { GiGearStickPattern } from "react-icons/gi";
import { FaRoad, FaWheelchair } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { getWav } from "../../redux/actions/wavActions";
import { useHistory } from "react-router-dom";
import "./grid-buttons.scss";
export default function SearchCardGridView({ wav }) {
  const [expanded, setExpanded] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  // const price = parseFloat(wav.price);
  let price = 0
  if (Object.entries(wav).length > 0) {
    wav.price_2 = wav.price.replace(/[^0-9]/g, "")
    price = parseFloat(wav.price_2);

    wav.mileage = wav.mileage.replaceAll(`\"`, "")

  }
  return (
    <Card
      onClick={() => {
        dispatch(getWav(wav._id));
        history.push(`/wavs/${wav._id}`);
      }}
      style={{ cursor: "pointer", borderRadius: "30px", padding: "10px" }}
    >
      <CardHeader className="option-header" title={wav.title} />
      <CardMedia
        component="img"
        className="wav-image"
        image={
          `https://dev.wavcompare.com/uploads/${wav.images[0]}` ||
          "https://www.exploremobility.co.uk/assets/imgs/jpgs/slide1.jpg"
        }
        alt="car"
      />
      <div className="grid-buttons">
        <a href="#" className="price-btn">
          <span>£{price.toLocaleString()}</span>
        </a>
        <a href="#" className="enquire-btn">
          <span>ENQUIRE NOW</span>
        </a>
      </div>
      <CardContent>
        <Typography className="options" variant="body2" color="text.secondary">
          <div className="icon-options">
            <div style={{ textTransform: "capitalize" }}>
              <span>
                <FaWheelchair />
              </span>
              {wav.condition}
            </div>
            <div>
              <span>
                <FaRoad />
              </span>
              {wav.mileage}
            </div>
          </div>
          <div className="icon-options">
            <div>
              <span>
                <FaWheelchair />
              </span>
              {wav.seating}
            </div>

            <div className="road">
              <span>
                <GiGearStickPattern />
              </span>
              {wav.transmission}
            </div>
          </div>
        </Typography>
      </CardContent>
    </Card>
  );
}

import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { wavReducer } from "./reducers/wavReducer";
import { dealerReducer } from "./reducers/dealerReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const initialStore = {
  wavs: {
    list: [],
    selected: {},
    searched: [],
    searchedWords: [],
    details: [],
  },
  dealers: {
    list: [],
    selected: {},
    dealer: {},
    authenticated: false,
    admin: false,
    modalOpen: false,
    selectedComponent: "",
    components: []
  },
  buyers: {
    list: [],
  },
};

const bigReducer = combineReducers({
  wavs: wavReducer,
  dealers: dealerReducer,
});

const configureStore = createStore(
  bigReducer,
  initialStore,
  composeEnhancers(applyMiddleware(thunk))
);

export { configureStore };

import React, { useState, useEffect } from "react";
import "./style.scss";
import { AiFillStar, AiOutlineClear, AiOutlineSearch } from "react-icons/ai";
import BasicSelect from "./SelectFIlter";
import { useDispatch, useSelector } from "react-redux";
import {
  searchWavs,
  getWavDetails,
  getWavs,
} from "../../../redux/actions/wavActions";
import { useHistory } from "react-router-dom";

const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
console.log("environment",environment)
const url = environment === "production" ? prodURL : devURL;

const MainLayout = () => {
  const [selected, setSelected] = useState("passengers");
  const [bodyType, setBodyType] = React.useState("");
  const [make, setMake] = React.useState("");
  const [features, setFeatures] = useState([]);
  const [model, setModel] = React.useState("");
  const [keywords, setKeywords] = React.useState("");
  const [alreadyAdded, setAlreadyAdded] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const detailsFromState = useSelector((state) => state.wavs.details);
  const wavsFromState = useSelector((state) => state.wavs.list);

  const filteredByPassenger = wavsFromState.filter(
    (wav) => wav.wav_type !== "Drive From Wheelchair"
  );

  const filteredByDriver = wavsFromState.filter(
    (wav) => wav.wav_type === "Drive From Wheelchair"
  );

  const filteredByType =
    selected === "passengers" ? filteredByPassenger : filteredByDriver;

  const filteredByBody =
    filteredByType.length > 0 &&
    filteredByType.filter((wav) => wav.body === bodyType);

  const filteredByMake =
    bodyType !== ""
      ? filteredByBody.filter((wav) => wav.make.trim() === make.trim())
      : filteredByType.length > 0 &&
        filteredByType.filter((wav) => wav.make.trim() === make.trim());

  const filteredByModel =
    make !== ""
      ? filteredByMake.filter((wav) => wav.models === model)
      : filteredByType.length > 0 &&
        filteredByType.filter((wav) => wav.models === model);
        
  const filteredByFeatures =
    model !== ""
      ? filteredByModel.filter((wav) => {
          const wavs = [];
          for (let keyword of keywords) {
            const featureIncluded = wav.features.includes(keyword);
            wavs.push(featureIncluded);
          }
          const notFalse = wavs.includes(false);
          if (!notFalse) {
            return wav;
          }
        })
      : wavsFromState.filter((wav) => {
          const wavs = [];
          for (let keyword of keywords) {
            const featureIncluded = wav.features.includes(keyword);
            wavs.push(featureIncluded);
          }
          const notFalse = wavs.includes(false);
          if (!notFalse) {
            return wav;
          }
        });

  const newUniqueFeatures = [];

  const filteredFeatures =
    filteredByModel &&
    filteredByModel.length > 0 &&
    filteredByModel.map((wav) => {
      wav.features.map((feature) => {
        const isUnique = newUniqueFeatures.includes(feature);
        if (!isUnique) {
          newUniqueFeatures.push(feature);
        }
      });
      return newUniqueFeatures;
    });

  const length =
    bodyType !== "" && make === "" && model === ""
      ? filteredByBody.length
      : make !== "" && model === ""
      ? filteredByMake.length
      : model !== "" && keywords.length <= 0
      ? filteredByModel.length
      : bodyType !== "" || make !== "" || model !== ""
      ? filteredByFeatures.length
      : filteredByType.length;

  const makes =
    bodyType === ""
      ? filteredByType.map((wav) => wav.make)
      : filteredByBody.length > 0 && filteredByBody.map((wav) => wav.make);

  const filteredMakes = [];
  const uniqueMakes =
    makes &&
    makes.length > 0 &&
    makes.filter((make) => {
      const isUnique = filteredMakes.includes(make);
      if (!isUnique) {
        filteredMakes.push(make);
      }
    });

  const models =
    make === ""
      ? filteredByType.map((wav) => wav.models)
      : filteredByMake.length > 0 && filteredByMake.map((wav) => wav.models);

  const filteredModels = [];
  const uniqueModels =
    models &&
    models.length > 0 &&
    models.filter((model) => {
      const isUnique = filteredModels.includes(model.trim());
      if (!isUnique) {
        filteredModels.push(model);
      }
    });

  useEffect(() => {
    getFeatures();
    dispatch(getWavs());
    dispatch(getWavDetails());
  }, []);
  const getFeatures = async () => {
    try {
      const response = await fetch(`${url}/features/all`);
      if (response.ok) {
        const data = await response.json();
        setFeatures(data.data.features);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const [inputCounter, setInputCounter] = useState(0)
  // console.log('inputCounter---', inputCounter)
  const handleChange = (e, type) => {
    if (e.target.value != '') {
      setInputCounter(inputCounter + 1)

    }
    if (type === "body") {
      setBodyType(e.target.value);
    } else if (type === "make") {
      setMake(e.target.value);
    } else if (type === "model") {
      setModel(e.target.value);
    } else {
      const keyword =
        keywords &&
        keywords.length > 0 &&
        keywords.find((keyword) => keyword === e.target.value);
      if (!keyword) {
        setKeywords([...keywords, e.target.value]);
      } else {
        setAlreadyAdded(true);
        setTimeout(() => {
          setAlreadyAdded(false);
        }, 2000);
      }
    }
  };
  const handleSubmit = () => {
    history.push("/search");
    // dispatch(searchWavs(bodyType, make, model, keywords));
    dispatch(
      searchWavs(
        bodyType,
        make,
        model,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        keywords,
        selected
      )
    );
  };

  const handleClear = () => {
    setBodyType("");
    setMake("");
    setModel("");
    setKeywords("");
  };
  const deleteKeyword = (btn) => {
    const filtered = keywords.filter((keyword) => keyword !== btn);

    setKeywords(filtered);
  };
  return (
    <div className="main-layout">
      <div id="main">
        <div className="search-wrapper">
          <h2 className="title">500+</h2>
          <div className="subtitle">
            Wheelchair Accessible Vehicles Available To Buy Now
          </div>
          <div className="search-component">
            <div className="upper-part">
              <div className="types d-flex">
                <div
                  onClick={() => setSelected("passengers")}
                  className={
                    selected === "passengers"
                      ? "passengers on-focus"
                      : "passengers"
                  }
                >
                  ADAPTED FOR PASSENGERS
                </div>
                <div
                  onClick={() => setSelected("drivers")}
                  className={
                    selected === "drivers" ? "drivers on-focus" : "drivers"
                  }
                >
                  ADAPTED FOR DRIVERS
                </div>
              </div>
            </div>
            <div className="lower-part">
              <div className="main">
                <div className="select-filter">
                  <BasicSelect
                    name="Choose Body"
                    bodyType={bodyType}
                    list={detailsFromState.body}
                    make={make}
                    model={model}
                    keywords={keywords}
                    handleChange={handleChange}
                  />
                </div>
                <div className="select-filter">
                  <BasicSelect
                    name="Choose Make"
                    bodyType={bodyType}
                    list={filteredMakes}
                    make={make}
                    model={model}
                    keywords={keywords}
                    handleChange={handleChange}
                  />
                </div>
                <div className="select-filter">
                  <BasicSelect
                    name="Choose Model"
                    bodyType={bodyType}
                    list={filteredModels}
                    make={make}
                    model={model}
                    keywords={keywords}
                    handleChange={handleChange}
                  />
                </div>
                <div className="select-filter">
                  <BasicSelect
                    name="Keywords"
                    bodyType={bodyType}
                    make={make}
                    // list={detailsFromState.features}
                    list={newUniqueFeatures}
                    model={model}
                    keywords={keywords}
                    handleChange={handleChange}
                  />
                </div>
                <div className="search-btns">
                  <button
                    onClick={handleSubmit}
                    className="btn search-btn  mt-3"
                  >
                    {/* <AiOutlineSearch /> {length} WAVS */}
                    <AiOutlineSearch /> {inputCounter > 0 && `${length} WAVS` }
                  </button>
                  <button onClick={handleClear} className="btn search-btn mt-3">
                    <AiOutlineClear /> CLEAR
                  </button>
                </div>
              </div>
              <div className="keywords">
                {keywords &&
                  keywords.length > 0 &&
                  keywords.map((keyword) => (
                    <button
                      style={{ textTransform: "capitalize" }}
                      onClick={(e) => {
                        e.preventDefault();
                        deleteKeyword(keyword);
                      }}
                      key={keyword}
                    >
                      {keyword}
                    </button>
                  ))}
              </div>
              {alreadyAdded && (
                <div className="already-added">Already in the list</div>
              )}
            </div>
          </div>
          {/* <div className="call-to-action">
          <div className="star">
            <AiFillStar color="yellow" />
          </div>
          <div className="text">
            <h2>We Buy Any WAV</h2>
            <div>Need a quick sale on your WAV?</div>
            <div>Call us now on 0800 832 1795 or fill in this form</div>
          </div>
          <div className="star">
            <AiFillStar color="yellow" />
          </div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

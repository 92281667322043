import React from "react";

const WheelchairBus = ({ history, setMenuOpen }) => {
  return (
    <div className="seller">
      <div className="text">
        <h1>Wheelchair Minibus</h1>
        <h4>Wheelchair Accessible Minibus</h4>
        <button
          onClick={() => {
            history.push("/wheelchair-accessible-minibus");
            setMenuOpen(false);
          }}
        >
          Click Here
        </button>
      </div>
      <div className="img">
        <img
          src="/assets/wheelchair-minibus.jpg"
          alt="seller"
          className="img-fluid"
        />
      </div>
    </div>
  );
};

export default WheelchairBus;

import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { BsFillShareFill } from "react-icons/bs";
import { FaWheelchair } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchWavs } from "../../redux/actions/wavActions";
const imgArray = [
  { name: "Ford", link: "/assets/FORD.svg" },
  { name: "Kia", link: "/assets/KIA.svg" },
  { name: "Nissan", link: "/assets/NISSAN.svg" },
  { name: "Mercedes", link: "/assets/MERCEDES.svg" },
  { name: "Peugeut", link: "/assets/PEUGEUT.svg" },
  { name: "Volkswagen", link: "/assets/VOLKSWAGEN.svg" },
];
const MiniBusMainContent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSearch = (make, model) => {
    history.push("/search");
    dispatch(searchWavs("", make, model));
  };

  return (
    <div className="minibus-main-content">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 minibus-main">
          <p>
            WAV Wheelchair Access Window Sticker Wheelchair Accessible Minibus
            March 4, 2020Posted by: WAVCompare No Comments If extra room is
            needed or there are more than one wheelchair user passenger then a
            wheelchair accessible minibus is most likley the answer. Naturally
            they are a larger vehicle and therefore has the adequate room needed
            accomadate more passengers, whether they remain seated in their own
            wheelchair or transfer to the ordinary minibus seats.
          </p>
          <p>
            Although most wheelchair access minbuses are used commercially there
            is an increasing number of individuals opting for this larger type
            of WAV as the extra room is of benefit. They are also becoming less
            ‘van like’ and nicer vehicles to drive in general which is
            increasing there popularity as a wheelchair accessible vehicle. For
            example the Renault Master and new model Ford Transit are nice
            looking vehicles and less like driving a large van.
          </p>
          <div className="select-image">
            <div>
              <strong className="text-danger">Select below image</strong> to
              receive an online quote – or call: <strong> 0800 776 5036</strong>
            </div>
            <img
              src="/assets/WAMB006.png"
              alt="minibus"
              className="img-fluid"
            />
          </div>
          <div className="why-opt-for-minibus">
            <h2>Why opt for an accessible minibus?:</h2>
            <ul>
              <li>
                <strong>More room!</strong> – there is space for up to 3
                wheelchair passengers depending on the make and model.
              </li>
              <li>
                <strong>Large wheelchairs</strong> – you can have a larger
                electric rear or even side lift.
              </li>
              <li>
                <strong>Transfer easier</strong> – you can transfer from
                wheelchair to seat easier and safer if there is more room and
                you can stand.
              </li>
            </ul>
            <img
              src="/assets/WAMB005.png"
              alt="minibus"
              className="img-fluid"
            />
            <div className="loading">
              <div className="icon">
                <AiOutlineLoading3Quarters />
              </div>
              <div className="text">
                We can help you find minibuses with wheelchair access (or any
                other WAV!) – browse our stock or contact a dealer near you
                here.
              </div>
            </div>
          </div>
          <div className="why-opt-for-wav">
            <h2>Why opt for a WAV?...</h2>
            <p>
              A wheelchair accessible minibus or WAV, as they are commonly
              called, makes transport enjoyable, comfortable and safe for
              wheelchair users. A vehicle where the wheelchair user is the
              driver offers the most independence and this is possible with a
              minibus conversion. However, if this is the right type of minibus
              for you will depend upon your physical capabilities and the amount
              of money you have available.
            </p>
            <p>
              These types of vehicles are almost always custom conversions which
              are built exactly to meet the specific needs of the user. Some are
              entered through the front side door by means of a lift which
              transport the user in the wheelchair directly into the driving
              position. Some people prefer to pass from their wheelchair into
              the driving seat using a special sliding seat adaption, and then
              they themselves can store the folded up wheelchair behind the
              driving seat. With the extra room in a wheelchair accessible
              minibus the transfer is often alot easier. Frequently
              modifications also have to be made to the way the car is actually
              operated, and all this means, that this type of wheelchair
              accessible vehicle is very expensive. A wheelchair accessible
              vehicle where the wheelchair user is the passenger is a more
              economic option as these can be purchased as standard conversions.
              You might even be able to find a used WAV which meets your needs,
              but be sure to revise the vehicle thoroughly, just as you would
              when buying any kind of vehicle. It is easy to forget things like
              the motor and the tyres and only concentrate on the converted
              features.
            </p>
            <img
              src="/assets/WAMB004.png"
              alt="minibus"
              className="img-fluid"
            />
          </div>
          <div className="why-opt-for-wav">
            <h2>
              What type of wheelchair accessible minibus is best for you?..
            </h2>
            <p>
              Things you need to consider when deciding what type of wheelchair
              accessible minibus is best for you. Do you want to travel in the
              rear part of the vehicle or in the front? Travelling in the front
              is usually more comfortable especially if you are tall, but
              conversions, where the wheelchair is secured in the rear, are more
              common. Would you prefer to enter through the rear, or through a
              side door? Rear door access requires quite a lot of room behind
              the vehicle. Side access can be safer, entering and exiting from
              the pavement rather than the road. Does a ramp or a lift offer
              easier access for you? Ramps are cheaper, lifts cost more and
              require regular maintenance. Why not rent a vehicle to discover
              which of these options suits you best? Always ask for a home trial
              before purchasing a wheelchair accessible vehicle. What size of
              vehicle do you require? How many passengers plus the wheelchair
              user will normally use the vehicle? Where are you going to park?
              Will it enter into your garage or driveway? Do you live on a rough
              road? If so you might ground out as often converted vehicles have
              a lowered floor to make access easier and to give sufficient
              headroom. They also frequently have smaller petrol tanks so
              remember to fill up regularly! Purchasing a minibus for wheelchair
              users is a big decision, so take your time and investigate the
              different options that are available. Only purchase from reputable
              dealers and check that the conversion meets all the required
              safety standards.
            </p>
            <img
              src="/assets/WAMB003.png"
              alt="minibus"
              className="img-fluid"
            />
          </div>
          <div className="mercedes-benz">
            <h2>Mercedes Benz:</h2>
            <p>
              A very nice option of minibus the Mercedes Benz would be suitable
              for several passengers depending on the configuration or chairs
              and how many wheelchair users as you could remove chairs to
              accomadate the wheelchair user.
            </p>
            {/* <div className="upper">
              <img
                src="https://www.minibus.ltd.uk/assets/35/images/2014_transit_15seat_l3h3_1256crop.jpg"
                alt="minibus"
                className="img-fluid"
              />
            </div> */}

            <div className="lower">
              <img
                src="/assets/WAMB001.png"
                alt="minibus"
                className="img-fluid"
              />
            </div>
          </div>
          <div className="minibus-footer">
            <div className="button">
              <button>Check out the following blogs:</button>
            </div>

            <div>
              <a href="#">Quickie Nitrum Wheelchair Available</a> |
              <a href="#"> Hire Car</a> | <a href="#">Mobility Scooters</a> |
              <a href="#"> Vauxhall Zafira</a> | <a href="#">Kia Sedona</a> |
              <a href="#"> Ride Up Front</a> | <a href="#">Best Self Drive</a> |
              <a href="#"> Wheelchair Accessible Minibus</a> |{" "}
              <a href="#"> Motorhome Hire</a> |<a href="#"> Ford Transit</a> |
              <a href="#"> Sell WAVs</a> | <a href="#">Tow Bar Options</a> |
              <a href="#"> Mountain Bike Wheels</a> |{" "}
              <a href="#"> Renault Master</a> |
              <a href="#"> Manual vs Automatic</a> | <a href="#">SoliHull</a> |
            </div>
            <div className="category-and-share">
              <div className="category">Category: Wavs</div>
              {/* <div className="share">
                <button>
                  <BsFillShareFill /> Share This
                </button>
              </div> */}
            </div>
            <div className="author">
              <div className="icon">
                <FaWheelchair />
              </div>
              <div className="text">
                <div className="text-muted">Author</div>
                <h4>WavCompare</h4>
                <div>UK's Leading WAV Specialist</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-0 col-md-3 col-lg-4 minibus-sidebar">
          <div className="used-wavs">
            <h2>Used WAVs</h2>
            <img
              src="https://www.alliedmobility.com/wp-content/uploads/2021/08/RAC-3for1_July21_HeaderImage_web2-1.jpg"
              alt=""
              className="img-fluid"
            />
            <img
              src="https://www.alliedmobility.com/wp-content/uploads/2021/08/RAC-3for1_July21_HeaderImage_web2-1.jpg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="new-wavs">
            <h2>New WAVs</h2>
            <img
              src="https://www.alliedmobility.com/wp-content/uploads/2021/08/RAC-3for1_July21_HeaderImage_web2-1.jpg"
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="recent-posts">
            <h2>Recent Posts</h2>
            <ul>
              <li>
                <a href="#">Life changing beach wheelchair given to family</a>
              </li>
              <li>
                <a href="#">
                  New partnership with SVR puts disabled people in the driving
                  seat
                </a>
              </li>
              <li>
                <a href="#">Quickie Nitrum Wheelchair Available</a>
              </li>
              <li>
                <a href="#">Wheelchair Accessible Vehicles Bromsgrove</a>
              </li>
              <li>
                <a href="#">Wheelchair Accessible Vehicles Cannock</a>
              </li>
            </ul>
          </div>
          <div className="top-makes">
            <h2>Top WAV Makes</h2>
            <div className="icons">
              {imgArray.slice(0, 3).map((img) => {
                return (
                  <div
                    className="icon"
                    onClick={() => handleSearch(img.name, "")}
                  >
                    <img src={img.link} alt="logo" className="image-fluid" />
                    <div>{img.name}</div>
                  </div>
                );
              })}
              {imgArray.slice(3, 6).map((img) => {
                return (
                  <div
                    className="icon"
                    onClick={() => handleSearch(img.name, "")}
                  >
                    <img src={img.link} alt="logo" />
                    <div>{img.name}</div>
                  </div>
                );
              })}
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniBusMainContent;

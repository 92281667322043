import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { searchWavs } from "../../redux/actions/wavActions";
const imgArray = [
  // { name: "Kia", link: "/assets/KIA.png" },
  { name: "Kia", link: "/assets/kia.png" },
  // { name: "Volkswagen", link: "/assets/VOLKSWAGEN.png" },
  { name: "Volkswagen", link: "/assets/volkswagen.png" },
  // { name: "Mercedes Benz", link: "/assets/MERCEDES.png" },
  { name: "Mercedes Benz", link: "/assets/mercedes.png" },
  // { name: "Vauxhall", link: "/assets/vauxhall1.png" },
  { name: "Nissan", link: "/assets/NISSAN.png" },
  // { name: "Toyota", link: "/assets/TOYOTA.png" },
  { name: "Toyota", link: "/assets/toyota.png" },
  { name: "Ford", link: "/assets/FORD.svg" },
];

const adImgs = [
  "/assets/ad.jpg",
  "/assets/ad1.jpg",
  "/assets/ad2.jpg",
  "/assets/ad.jpg",
  "/assets/ad.jpg",
  "/assets/ad1.jpg",
];
const WavType = ({ wavs, type }) => {
  const featured = wavs.filter((wav) => wav.featured);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleSearch = (make, model) => {
    dispatch(searchWavs("", make, model));
    history.push("/search");
  };

  return (
    <div className="wav-type">
      <div className="row">
        <div className="col-md-12 col-lg-12 py-3">
          {/* <h6>Top WAV Makes</h6> */}
          <h6>{`${type == 'old' ? "Used" : type} Makes`}</h6>
          <div
            className="row wavtype-wrapper border-0"
          // style={{ height: "80%", borderRight: "1px solid #ccc" }}
          >
            {type === "new" &&
              imgArray.slice(0, 3).map((img) => (
                <div
                  onClick={() => handleSearch(img.name)}
                  key={img.name}
                  className="image-wrapper"
                >
                  <img
                    src={img.link}
                    style={
                      img.name === "Ford"
                        ? { height: "100%", width: "100%", marginTop: "-40px" }
                        : img.name === "Kia"
                          ? { height: "60px", width: "90px", marginTop: "5px" }
                          : {}
                    }
                    className="card-img-top"
                    alt="..."
                  />
                </div>
              ))}
            {type === "old" &&
              imgArray.slice(0, 5).map((img) => (
                <div
                  onClick={() => handleSearch(img.name)}
                  key={img.name}
                  className="image-wrapper"
                >
                  <img
                    src={img.link}
                    style={
                      img.name === "Ford"
                        ? { height: "100%", width: "100%", marginTop: "-40px" }
                        : img.name === "Kia"
                          ? { height: "60px", width: "90px", marginTop: "5px" }
                          : {}
                    }
                    className="card-img-top"
                    alt="..."
                  />
                </div>
              ))}
            {type === "mobility" &&
              imgArray.slice(3, 7).map((img) => (
                <div
                  onClick={() => handleSearch(img.name)}
                  key={img.name}
                  className="image-wrapper"
                  style={img.name === "Ford" ? { marginTop: "-40px" } : {}}
                >
                  <img
                    src={img.link}
                    style={
                      img.name === "Ford"
                        ? { height: "100%", width: "100%", marginTop: "-40px" }
                        : img.name === "Kia"
                          ? { height: "100%", width: "100%" }
                          : {}
                    }
                    className="card-img-top"
                    alt="..."
                  />
                </div>
              ))}
            {/* <div className="col-md-4">
              <div className="card">
                <div className="image-wrapper">
                  <img
                    src="/assets/fiat.png"
                    className="card-img-top"
                    alt="..."
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-4">
              <div className="card">
                <div className="image-wrapper">
                  <img
                    src="/assets/peugeot1.png"
                    className="card-img-top"
                    alt="..."
                  />
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="image-wrapper">
                  <img
                    src="/assets/mono.png"
                    className="card-img-top"
                    alt="..."
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* {
          (
            <div className="col-md-12 col-lg-4 py-3 list">
              <h6>Top WAVs</h6>
              <div className="" style={{ height: "80%" }}>
                <ul>
                  {featured &&
                    featured.length > 0 &&
                    featured.slice(0, 4).map((wav) => (
                      <li onClick={() => handleSearch("", wav.models)}>
                        <a href="#">{wav.models}</a>
                      </li>
                    ))}
                </ul>
                <ul>
                  {featured &&
                    featured.length > 0 &&
                    featured.slice(4, 8).map((wav) => (
                      <li onClick={() => handleSearch("", wav.models)}>
                        <a href="#">{wav.models}</a>
                      </li>
                    ))}{" "}
                </ul>
              </div>
            </div>
          )
        } */}

        {/* <div className="col-md-12 col-lg-4">
          <div className="ads">
            {type === "new" &&
              adImgs.slice(0, 2).map((img) => (
                <div key={img} className="ad">
                  <img src={img} alt="car" className="img-fluid" />
                </div>
              ))}
            {type === "old" &&
              adImgs.slice(2, 4).map((img) => (
                <div key={img} className="ad">
                  <img src={img} alt="car" className="img-fluid" />
                </div>
              ))}
            {type === "mobility" &&
              adImgs.slice(4, 6).map((img) => (
                <div key={img} className="ad">
                  <img src={img} alt="car" className="img-fluid" />
                </div>
              ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default WavType;

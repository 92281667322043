import React, { useEffect, useState } from "react";
import HireCards from "./HireCards";
import HireDateAndPasscode from "./HireDateAndPasscode";
import HireDetails from "./HireDetails";
import HireHeader from "./HireHeader";
import "./style.css";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

const Hire = () => {
  const [size, setSize] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [postCode, setPostCode] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [selectedCar, setSelectedCar] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  const sendEmail = async () => {
    const emailObj = {
      size,
      duration,
      startDate,
      postCode,
      name,
      email,
      phone,
      message,
      subject,
    };

    try {
      const response = await fetch(`${url}/hire/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailObj),
      });
      if (response.ok) {
        const data = await response.json();
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
        setStartDate(null);
        setPostCode("");
        setSize("");
        setDuration("");
        setSubject("");
        setSelectedCar("")
        setSelectedDate("")
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      } else {
        const data = await response.json();
        console.log("error: ", data);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <form onSubmit={handleSubmit} id="hire">
      <HireHeader />
      <HireCards 
        setSize={setSize} 
        selectedCar={selectedCar}
        selectedDate={selectedDate}
        setDuration={setDuration} 
        setSelectedDate={setSelectedDate}
        setSelectedCar={setSelectedCar}
      />
      <HireDateAndPasscode
        setStartDate={setStartDate}
        setPostCode={setPostCode}
        postCode={postCode}
        startDate={startDate}
      />
      <HireDetails
        setEmail={setEmail}
        email={email}
        setPhone={setPhone}
        phone={phone}
        setName={setName}
        name={name}
        setMessage={setMessage}
        message={message}
        subject={subject}
        setSubject={setSubject}
      />
      {success && (
        <div className="alert alert-success mt-3 w-50 mx-auto" role="alert">
          Your request has been sent successfully
        </div>
      )}
      {error && (
        <div className="alert alert-danger mt-3" role="alert">
          Your request has not been sent. Please fill all the details.
        </div>
      )}
    </form>
  );
};

export default Hire;

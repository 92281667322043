import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { GiGearStickPattern } from "react-icons/gi";
import { FaRoad, FaWheelchair, FaShareAlt } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import { useHistory } from "react-router-dom";
import shortid from "shortid";
import { BiShow, BiHide } from "react-icons/bi";
import "./buttons.scss";
const SearchCard = ({ wav }) => {
  const history = useHistory();
  const [showNumber, setShowNumber] = useState(false);
  const shortenedText = wav.description.substring(0, 100);
  const navigate = (id) => {
    history.push(`/dealers/${id}`);
  };
  // const price = parseFloat(wav.price);
  let price = 0
  if (Object.entries(wav).length > 0) {
    wav.price_2 = wav.price.replace(/[^0-9]/g, "")
    price = parseFloat(wav.price_2);

    wav.mileage = wav.mileage.replaceAll(`\"`, "")

  }
  const phone_number = (Object.entries(wav).length > 0) && wav.owner && wav.owner.phone_number;

  if ((Object.entries(wav).length > 0) && wav.featured_image && wav.images) {
    let filtered = wav.images.filter((ele) => ele != wav.featured_image);
    wav.images = [wav.featured_image, ...filtered]
  }

  return (
    <div className="card-main-content">
      <div className="card w-80 mt-5">
        <div
          className="card-top"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(`/wavs/${wav._id}`)}
        >
          <h5>{wav.title}</h5>
          <p>
            <small>{shortenedText}...</small>
          </p>
        </div>
        <div className="card-body-carousel">
          <div className="left">
            <Carousel>
              {wav.images.length > 0 &&
                wav.images.map((image, i) => (
                  <Carousel.Item key={i}>
                    <img
                      className="d-block w-100 img-fluid carousel-img"
                      src={`https://dev.wavcompare.com/uploads/${image}`}
                      alt={`slide ${i}`}
                      style={{ maxHeight: "400px" }}
                      key={shortid.generate()}
                    />
                  </Carousel.Item>
                ))}
            </Carousel>
          </div>
          <div className="right">
            <div className="card-list-details">
              <ul>
                <li>
                  <div>Make</div> <div>{wav.make}</div>
                </li>
                <li>
                  <div>Year</div> <div>{wav.year}</div>
                </li>
                <li>
                  <div>Colour</div> <div>{wav.exterior_colour}</div>
                </li>
                <li>
                  <div>Fuel</div> <div>{wav.fuel_type}</div>
                </li>
                <li>
                  <div>Model</div> <div>{wav.models}</div>
                </li>
                <li>
                  <div>Condition</div> <div>{wav.condition}</div>
                </li>
                <li>
                  <div>Seating</div> <div>{wav.seating}</div>
                </li>
              </ul>
            </div>
            <div className="rest">
              <div className="card-icon-options">
                <div className="first-column">
                  <div>
                    <span>
                      <AiFillStar />
                    </span>
                    {wav.condition}
                  </div>
                  <div>
                    <span>
                      <FaRoad />
                    </span>
                    {wav.mileage}
                  </div>
                </div>
                <div className="second-column">
                  <div>
                    <span>
                      <GiGearStickPattern />
                    </span>
                    {wav.transmission}
                  </div>
                  <div>
                    <span>
                      <FaWheelchair />
                    </span>
                    {wav.seating}
                  </div>
                </div>
              </div>
              <div className="card-contact-details">
                <ul>
                  <li>
                    <img
                      src={
                        wav && wav.owner && wav.owner.logo !== ""
                          ? `https://dev.wavcompare.com/uploads/${wav.owner.logo}`
                          : "/assets/user.jpg"
                      }
                      className="owner-img"
                      alt=""
                    />
                  </li>
                  <li className="number">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(wav.owner._id)}
                    >
                      {wav && wav.owner && wav.owner.name}
                    </div>
                    <IoMdCall />{" "}
                    {showNumber
                      ? phone_number
                      : `${phone_number.substring(0, 4)}*****`}
                    <span onClick={() => setShowNumber(!showNumber)}>
                      {showNumber ? <BiHide /> : <BiShow />}
                    </span>
                  </li>
                </ul>
                {/* <button className="btn btn-outline-secondary btn-small share-this">
                  <span className="mr-3">
                    <FaShareAlt color="#28b8cb" />
                  </span>
                  SHARE THIS
                </button> */}
              </div>
              <div className="card-buttons">
                <a style={{ cursor: "default" }} className="price-btn">
                  <span>£{price.toLocaleString()}</span>
                </a>
                <a
                  onClick={() => history.push(`/wavs/${wav._id}`)}
                  className="enquire-btn"
                >
                  <span>ENQUIRE NOW</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchCard;

import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { AiFillStar, AiOutlineCheck } from "react-icons/ai";
import { GiGearStickPattern } from "react-icons/gi";
import { FaRoad, FaWheelchair, FaShareAlt } from "react-icons/fa";
import { IoMdCall } from "react-icons/io";
import ProductCarousel from "./ProductCarousel";
import ProductWavDetails from "./ProductWavDetails";
import ProductWavFeatures from "./ProductWavFeatures";
import { BiShow, BiHide } from "react-icons/bi";
import { useHistory } from "react-router-dom";
const ProductCard = ({ wav }) => {
  const [showNumber, setShowNumber] = useState(false);
  const history = useHistory();
  const navigate = (id) => {
    history.push(`/dealers/${id}`);
  };
  let price = 0
  if (Object.entries(wav).length > 0) {
    wav.price_2 = wav.price.replace(/[^0-9]/g, "")
    price = parseFloat(wav.price_2);

    wav.mileage = wav.mileage.replaceAll(`\"`, "")

  }


  console.log('wav', wav)
  const shortenedText =
  (Object.entries(wav).length > 0) && wav.description && wav.description.toString().substring(0, 100);

  if ((Object.entries(wav).length > 0) && wav.featured_image && wav.images) {
    let filtered = wav.images.filter((ele) => ele != wav.featured_image);
    wav.images = [wav.featured_image, ...filtered]
  }

  return (
    wav && (
      <div className="product-main-wrapper">
        <div className="card mt-5">
          <h5>{wav.title}</h5>
          <p className="short-description">
            <small>{shortenedText}...</small>
          </p>
          <div className="price-badge">
            <a className="">
              <span>
                £{"  "}
                {price.toLocaleString()}
              </span>
            </a>
          </div>
          <div className="product-card-body-carousel">
            <div className="left">
              {wav && wav.images && <ProductCarousel images={wav.images} />}
            </div>

            <div className="right">
              <div className="product-card-icon-options">
                <div>
                  <span>
                    <AiFillStar />
                  </span>
                  {wav.condition}
                </div>
                <div>
                  <span>
                    <GiGearStickPattern />
                  </span>
                  {wav.transmission}
                </div>
              </div>
              <div className="product-card-icon-options">
                <div>
                  <span>
                    <FaRoad />
                  </span>
                  {wav.mileage}
                </div>
                <div>
                  <span>
                    <FaWheelchair />
                  </span>
                  {wav.seating}
                </div>
              </div>
              <div className="product-card-list-details">
                <ul>
                  <li>
                    <div>Make</div> <div>{wav.make}</div>
                  </li>
                  <li>
                    <div>Year</div> <div>{wav.year}</div>
                  </li>
                  <li>
                    <div>Colour</div> <div>{wav.exterior_colour}</div>
                  </li>
                  <li>
                    <div>Fuel</div> <div>{wav.fuel_type}</div>
                  </li>
                  <li>
                    <div>Model</div> <div>{wav.models}</div>
                  </li>
                  <li>
                    <div>Condition</div> <div>{wav.condition}</div>
                  </li>
                  <li>
                    <div>Seating</div> <div>{wav.seating}</div>
                  </li>
                </ul>
              </div>
              <div className="product-card-contact-details">
                <ul>
                  <li>
                    <img
                      src={
                        wav && wav.owner && wav.owner.logo !== ""
                          ? `https://dev.wavcompare.com/uploads/${wav.owner.logo}`
                          : "/assets/user.jpg"
                      }
                      className="product-owner-img"
                      alt=""
                    />
                  </li>
                  <li className="number">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(wav.owner._id)}
                    >
                      {wav && wav.owner && wav.owner.name}
                    </div>
                    <IoMdCall />
                    {showNumber
                      ? wav && wav.owner && wav.owner.phone_number
                      : `${wav &&
                      wav.owner &&
                      wav.owner.phone_number.substring(0, 4)
                      }*****`}
                    <span onClick={() => setShowNumber(!showNumber)}>
                      {showNumber ? <BiHide /> : <BiShow />}
                    </span>
                  </li>
                </ul>
                {/* <button className="btn btn-outline-secondary btn-small share-this">
                  <span className="mr-3">
                    <FaShareAlt color="#28b8cb" />
                  </span>
                  SHARE THIS
                </button> */}
              </div>
              <div className="product-card-description">
                <h6>DESCRIPTION</h6>
                <p>{wav.description}</p>
              </div>
            </div>
          </div>
          <div className="product-card-wav-details-wrapper">
            {wav && <ProductWavDetails wav={wav} />}
          </div>
          <div className="product-card-wav-features-wrapper">
            {wav && <ProductWavFeatures wav={wav} />}
          </div>
        </div>
      </div>
    )
  );
};

export default ProductCard;

import React from "react";

const HireDetails = ({
  email,
  setEmail,
  phone,
  setPhone,
  name,
  setName,
  message,
  setMessage,
  subject,
  setSubject,
}) => {
  return (
    <div className="hire-details">
      <h3>Please enter your details</h3>
      <div className="row">
        <div className="col-md-6 video-div">
          <div className="form-group">
            <label htmlFor="subject">Subject: (required)</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
              className="form-control"
              id="subject"
              required
              placeholder=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="more-info">More Info: (required)</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="form-control"
              id="more-info"
              rows="5"
              required
            ></textarea>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="email">Your Name: (required)</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="form-control"
              id="name"
              required
              placeholder=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Your Phone: (required)</label>
            <input
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              type="text"
              className="form-control"
              id="phone"
              required
              placeholder=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Your Email: (required)</label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="email"
              className="form-control"
              id="email"
              required
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div className="text-center">
        <button type="submit">CHECK AVAILABLE WAVS TO HIRE</button>
      </div>
    </div>
  );
};

export default HireDetails;
